.registration {
    border-bottom: 1px solid #b4a376 !important;

}

.form-control {
    border: 1px solid #b4a376;
    color: #4F4D4D !important;
}
.form-select{
    border: 1px solid #b4a376;
    color: #4F4D4D !important;
}

.form-label {   
    color: #4F4D4D !important;
}
.login-btn{
    border-radius: 5px;
    border: 1px solid #b4a376;
    width: 100%;
    color: #b4a376;
    font-size: 18px;
    font-weight: 600;
}
.login-btn:hover{
    border-radius: 5px;
    border: 1px solid #b4a376;
    width: 100%;
    background-color: #b4a376;
    color: #4F4D4D;
    font-size: 18px;
    font-weight: 600;
}